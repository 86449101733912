import { DOCUMENT } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { emitIframeMessage } from '@longnecktech/splash-commons-fe';
import { IframeMessage } from '@longnecktech/splash-commons-fe/dist/types/iframe-message';
import { GamesService } from '@services/games.service';
import { HeightObserverDirective } from '@shared/directives/height-observer.directive';
import {
  take,
  filter,
  combineLatest,
  delay,
  map,
  distinctUntilChanged,
} from 'rxjs';

import { SessionService } from '@services/session.service';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  hostDirectives: [HeightObserverDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  user$ = this.session.user$;
  isMobile$ = this.session.isMobile$;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private session: SessionService,
    private gameService: GamesService,
    @Inject(DOCUMENT) private document: Document,
    private heightObserver: HeightObserverDirective,
  ) {}

  ngOnInit(): void {
    this.updateBodyClass();
    this.initGame();
    this.initIsMobile();
    this.listenToHeightChanges();
  }

  private initGame(): void {
    this.user$
      .pipe(
        take(1),
        filter((user) => !!user),
      )
      .subscribe(() => {
        this.gameService.fetchGame().subscribe();
      });
  }

  private initIsMobile(): void {
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(untilDestroyed(this))
      .subscribe((result) => this.session.setIsMobile(result.matches));
  }

  private updateBodyClass(): void {
    const bodyClass = 'mobile-version';
    this.isMobile$.pipe(untilDestroyed(this)).subscribe((isMobile) => {
      if (isMobile) {
        this.document.body.classList.add(bodyClass);
      } else {
        this.document.body.classList.remove(bodyClass);
      }
    });
  }

  private listenToHeightChanges(): void {
    // run this code only for mobile devices
    combineLatest([this.isMobile$, this.heightObserver.heightChange])
      .pipe(
        untilDestroyed(this),
        filter(([isMobile]) => isMobile !== null && isMobile),
        map(([, height]) => height),
        distinctUntilChanged(),
        delay(300),
      )
      .subscribe((height) => {
        emitIframeMessage(IframeMessage.UpdateHeight, { heightInPx: height });
      });
  }
}

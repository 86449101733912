import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError, switchMap, take } from 'rxjs';
import { SessionService } from '@services/session.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private session: SessionService) {}

  intercept(
    httpRequest: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return this.session.user$.pipe(
      take(1),
      switchMap((user) => {
        if (user) {
          httpRequest = httpRequest.clone({
            setHeaders: {
              Authorization: 'Bearer ' + user.token,
            },
          });
        }
        return next.handle(httpRequest).pipe(
          catchError((error) => {
            return throwError(() => new Error(error.message));
          }),
        );
      }),
    );
  }
}

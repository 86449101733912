import {
  Directive,
  Output,
  EventEmitter,
  ElementRef,
  OnInit,
  OnDestroy,
} from '@angular/core';

@Directive({
  selector: '[appHeightObserver]',
  standalone: true,
})
export class HeightObserverDirective implements OnInit, OnDestroy {
  @Output() heightChange = new EventEmitter<number>();

  private observer: ResizeObserver;
  private lastHeight = 0;

  constructor(private elementRef: ElementRef) {
    this.observer = new ResizeObserver((entries) => {
      const newHeight = entries[0].contentRect.height;
      if (newHeight !== this.lastHeight) {
        this.lastHeight = newHeight;
        this.heightChange.emit(newHeight);
      }
    });
  }

  ngOnInit() {
    this.observer.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }
}
